

.width-7 {
    width: 10rem;
}

.width-profile-50 {
    width: 50px !important;
}

@media screen and (max-width: 1200px) {
    .width-7 {
        width: 10rem;
    }
}

@media screen and (max-width: 992px) {
    .width-7 {
        width: 10rem;
    }
}

@media screen and (max-width: 768px) {
    .width-7 {
        width: 10rem;
    }
}

@media screen and (max-width: 576px) {
    .width-7 {
        width: 10rem;
    }
}

@media screen and (max-width: 500px) {
    .width-7 {
        width: 8rem;
    }
}

@media screen and (max-width: 500px) {
    .width-7 {
        width: 7rem;
    }
}