/* banner carouse css  */

.bannerN{
    width: 95%;
    left: 0.5rem;
}

.bannerC{
    width: 93%;
}

.bannerCarouselIndicators .carousel-indicators {
    bottom: -3rem !important;
    position: absolute !important;
}

.bannerCarouselIndicators .carousel-indicators button {
    background-color: #D9D8D8 !important;
    width: 20px !important;
    height: 10px !important;
    border-radius: 15px !important;
    opacity: 1 !important;
    border: 0 !important;
}

.bannerCarouselIndicators .carousel-indicators button.active {
    width: 40px !important;
    background: #949494 !important;
}

.p5{
    padding: 3rem;
}

.gap5{
    gap: 3rem;
}

.gap3{
    gap: 2rem;
}

.font1{
    font-size: 1rem;
}

.font1half{
    font-size: 1.5rem;
}

.font2{
    font-size: 2rem;
}

.font3{
    font-size: 3rem;
}

.font4{
    font-size: 4rem;
}

.font5{
    font-size: 5rem;
}

.font6{
    font-size: 6rem;
}

.wrapboxRow{
    flex-direction: column;
}

.wrapboxCol{
    flex-direction: row;
}

.width400{
    width: 400px;
}

.bannerCH{
    height: 38vw;
}

/* .dynamic-texts{
    height: 90px;
    overflow: hidden;
}

.dynamic-texts li{
    list-style: none;
    color: var(--primary);
    position: relative;
    top: 0;
    animation: slide 6s steps(3) infinite;
}

.dynamic-texts li::after{
    content: "";
    position: absolute;
    background-color: white;
    height: 100%;
    width: 100%;
    border-left: 2px solid var(--primary);
    left: 0;
    animation: typing 1.5s steps(9) infinite;
}


@keyframes slide {
    100%{
        top: -280px;
    }
}


@keyframes typing {
    100%{
        left: 100%;
    }
} */

@media screen and (max-width: 1250px) {
    .bannerCH{
        height: 40vw;
    }
    .font6{
        font-size: 5rem;
    }
    .gap5{
        gap: 2rem;
    }
    .gap3{
        gap: 1.75rem;
    }
    .p5{
        padding: 2rem;
    }
    .font2{
        font-size: 1.75rem;
    }
    .font3{
        font-size: 2.5rem;
    }
    .font4{
        font-size: 3rem;
    }
    .width400{
        width: 260px;
    }
}

@media screen and (max-width: 800px) {
    .bannerCH{
        height: 43vw;
    }
    .font6{
        font-size: 3.5rem;
    }
    .wrapboxRow{
        flex-direction: row;
    }
    .wrapboxCol{
        flex-direction: column;
    }
    .gap5{
        gap: 1rem;
    }
    .gap3{
        gap: 1.5rem;
    }
    .p5{
        padding: 1.5rem;
    }
    .font1half{
        font-size: 1.2rem;
    }
    .font2{
        font-size: 1.5rem;
    }
    .font3{
        font-size: 1.75rem;
    }
    .font4{
        font-size: 2rem;
    }
    .width400{
        width: 150px;
    }
}

@media screen and (max-width: 700px) {
    .bannerCarouselIndicators .carousel-indicators button{
        height: 5px !important;
        width: 10px !important;
    }
    .bannerCarouselIndicators .carousel-indicators button.active{
        width: 20px !important;
    }
    .widthResCH{
        position: absolute !important;
        right: 0 !important;
        width: 150px !important;
        z-index: 1 !important;
    }
    /* .bannerCH{
        height: 60vw !important;
        padding-left: 1.5rem !important;
        padding-right: 1rem !important;
    } */
    .borderCH{
        border-width: 4px !important;
        border-radius: 0.6rem !important;
    }
    .marginCH{
        margin: 0.5rem 0px !important;
    }
    .paddingCH{
        padding: 0.2rem 0.3rem !important;
    }
    .logoCH{
        width: 80px;
    }
    .font2CH{
        font-size: 1.2rem !important;
    }
    .font1halfCH{
        font-size: 1rem !important;
    }
    .font1CH{
        font-size: 0.8rem !important;
    }
    .paddingC{
        padding-top: 0rem !important;
    }
}

@media screen and (max-width: 550px) {
    .bannerCH{
        height: 43vw;
    }
    .font4{
        font-size: 1.7rem;
    }
    .font3{
        font-size: 1.5rem;
    }
    .font2{
        font-size: 1.3rem;
    }
    .font1half{
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 450px) {
    .bannerCH{
        height: 43vw;
    }
}