.font-type-1 {
    font-size: 55px !important;
}

.font-type-2 {
    font-size: 30px !important;
}

.font-type-3 {
    font-size: 50px !important;
}

.font-type-4 {
    font-size: 20px !important;
}

.font-type-5 {
    font-size: 16px !important;
}

.font-type-6 {
    font-size: 12px !important;
}

.font-type-7 {
    font-size: 35px !important;
}

.font-type-8 {
    font-size: 22px !important;
}

.font-type-9 {
    font-size: 18px !important;
}

.font-180 {
    font-size: 1.8rem !important;
}

.font-300 {
    font-size: 3rem !important;
}

.font-profile-90 {
    font-size: 0.9rem !important;
}

.font-profile-80 {
    font-size: 0.8rem !important;
}

.font-refer-95 {
    font-size: 0.95rem !important;
}

.fw-4 {
    font-weight: 400;
}

.fw-5 {
    font-weight: 500 !important;
}

.fw-6 {
    font-weight: 600 !important;
}

.fw-7 {
    font-weight: 700 !important;
}

.fw-8 {
    font-weight: 800 !important;
}

@media screen and (max-width: 1200px) {
    .font-type-1 {
        font-size: 48px !important;
    }

    .font-type-3 {
        font-size: 42px !important;
    }

    .font-180 {
        font-size: 1.6rem !important;
    }

    .font-300 {
        font-size: 2.8rem !important;
    }

    .font-refer-95 {
        font-size: 0.8rem !important;
    }
}

@media screen and (max-width: 992px) {
    .font-type-1 {
        font-size: 38px !important;
    }

    .font-type-2 {
        font-size: 28px !important;
    }

    .font-type-3 {
        font-size: 34px !important;
    }

    .font-type-4 {
        font-size: 18px !important;
    }

    .font-type-5 {
        font-size: 15px !important;
    }

    .font-type-6 {
        font-size: 11px !important;
    }

    .font-type-7 {
        font-size: 30px !important;
    }

    .font-type-8 {
        font-size: 18px !important;
    }

    .font-type-9 {
        font-size: 15px !important;
    }

    .font-180 {
        font-size: 1.4rem !important;
    }

    .font-300 {
        font-size: 2.5rem !important;
    }
}

@media screen and (max-width: 768px) {

    .font-type-1 {
        font-size: 32px !important;
    }

    .font-type-2 {
        font-size: 22px !important;
    }

    .font-type-3 {
        font-size: 28px !important;
    }

    .font-type-7 {
        font-size: 28px !important;
    }

    .font-180 {
        font-size: 1.1rem !important;
    }

    .font-300 {
        font-size: 2.2rem !important;
    }

    .font-refer-100 {
        font-size: 1rem !important;
    }

    .font-refer-95 {
        font-size: 0.95rem !important;
    }
}

@media screen and (max-width: 576px) {

    .font-type-1 {
        font-size: 25px !important;
    }

    .font-type-2 {
        font-size: 18px !important;
    }

    .font-type-3 {
        font-size: 22px !important;
    }

    .font-type-4 {
        font-size: 16px !important;
    }

    .font-type-5 {
        font-size: 12px !important;
    }

    .font-type-6 {
        font-size: 9px !important;
    }

    .font-type-7 {
        font-size: 24px !important;
    }

    .font-180 {
        font-size: 0.9rem !important;
    }

    .font-300 {
        font-size: 1.7rem !important;
    }

    .font-refer-100 {
        font-size: 0.9rem !important;
    }

    .font-refer-95 {
        font-size: 0.85rem !important;
    }
}

@media screen and (max-width: 400px) {
    .font-type-1 {
        font-size: 22px !important;
    }

    .font-type-2 {
        font-size: 16px !important;
    }

    .font-type-3 {
        font-size: 18px !important;
    }

    .font-type-4 {
        font-size: 14px !important;
    }

    .font-type-5 {
        font-size: 10px !important;
    }

    .font-type-6 {
        font-size: 8px !important;
    }

    .font-refer-100 {
        font-size: 0.85rem !important;
    }

    .font-refer-95 {
        font-size: 0.75rem !important;
    }
}