.question-image-2 {
  position: relative;
  left: 35%;
}

.blob {
  position: absolute;
  width: 140%;
  max-width: 80vw;
  height: 100%;
  /* background: white; */
  /* left: calc(50% - 872.63px/2 - 8.69px); */
  /* top: 0;
    bottom: 0; */
  /* background: linear-gradient(180deg, #27D646 0%, #3D944B 100%); */
  filter: blur(125.15px);
  z-index: 3;
}

.blob1 {
  position: absolute;
  width: 40vw;
  top: -10%;
  right: -10%;
  filter: blur(95.15px);
  z-index: 3;
}

.blob2 {
  position: absolute;
  width: 45vw;
  top: 10%;
  left: -20%;
  filter: blur(125.15px);
  z-index: 3;
}

.blob2V2 {
  position: absolute;
  width: 55vw;
  top: 20%;
  left: -20%;
  filter: blur(125.15px);
  z-index: 3;
}

.blob3 {
  position: absolute;
  width: 45vw;
  bottom: 30%;
  left: -20%;
  filter: blur(125.15px);
  z-index: 3;
}

.blob4 {
  position: absolute;
  width: 45vw;
  bottom: 5%;
  right: -20%;
  filter: blur(125.15px);
  z-index: 3;
}

.float-icons {
  position: absolute;
  z-index: 3;
  width: fit-content;
}

.float-noti-icons {
  display: block;
}

.float-icon-1 {
  right: 0px;
  top: 3%;
}

.float-icon-2 {
  right: 0px;
  top: 9%;
}

.float-icon-3 {
  right: 0px;
  top: 20%;
}

.float-icon-4 {
  left: 0px;
  top: 3%;
}

.float-icon-5 {
  left: 20%;
  top: 18%;
}

.float-icon-6 {
  left: 0px;
  top: 25%;
}

.float-icon-7 {
  left: 30%;
  top: 2%;
}

.float-icon-8 {
  right: 28%;
  top: 3%;
}

.float-icon-9 {
  left: 31%;
  top: 14%;
}

.float-icon-10 {
  right: 25%;
  top: 17%;
}

.float-icon-11 {
  left: 23%;
  top: 10%;
}

.float-icon-12 {
  right: 24%;
  top: 11%;
}

.float-icon-13 {
  left: 20%;
  top: 24%;
}

.float-icon-14 {
  right: 25%;
  top: 25%;
}

.community-gradient-image {
  max-height: 680px;
}

.community-font-gradient {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  text-fill-color: transparent !important;
}

.community-font-1 {
  font-size: 70px;
  background: linear-gradient(
    178.51deg,
    #fff7f8 50%,
    rgba(255, 247, 248, 0) 98.74%
  );
}

.community-fontSize-1 {
  font-size: 70px;
}

.community-font-2 {
  color: var(--gray-500);
  font-size: 35px;
  line-height: 70px;
}

.community-fontSize-2 {
    font-size: 35px;
    line-height: 70px;
}

.community-font-3 {
  font-size: 60px;
  background: linear-gradient(
    178.51deg,
    #fff7f8 50%,
    rgba(255, 247, 248, 0) 98.74%
  );
  line-height: 80px;
}

.community-font-4 {
  color: var(--gray-300);
  font-size: 25px;
}

.community-fontSize-5 {
  font-size: 20px;
}

.community-fontSize-4 {
  font-size: 22px;
}

.question-image-container {
  /* overflow-x: hidden !important;
    overflow-y: visible !important; */
  overflow: hidden;
}

.community-footer-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 5rem;
  padding-bottom: 12rem;
}

.primary-color {
  color: #2823c6 !important;
}

.primary-bg {
  background-color: #2823c6 !important;
}

.community-black-fade {
  position: absolute;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 15%,
    rgba(0, 0, 0, 0.9) 75%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 10;
  left: 0;
  right: 0;
  bottom: -120px;
  top: -220px;
}

@media screen and (max-width: 1300px) {
  .community-font-1 {
    font-size: 55px;
  }

  .community-fontSize-1 {
    font-size: 55px;
  }

  .community-font-2 {
    font-size: 28px;
    line-height: 60px;
  }

  .community-fontSize-2 {
    font-size: 32px;
    line-height: 64px;
}

  .community-font-3 {
    font-size: 48px;
    line-height: 60px;
  }

  .community-font-4 {
    font-size: 20px;
  }

  .community-fontSize-4 {
    font-size: 18px;
  }

  .community-fontSize-5 {
    font-size: 16px;
  }

  .question-image-2 {
    left: 20%;
  }

  .community-gradient-image {
    max-height: 600px;
  }
}

@media screen and (max-width: 1150px) {
  .float-user-icons img {
    width: 45px;
  }

  .community-font-1 {
    font-size: 45px;
  }

  .community-fontSize-1 {
    font-size: 45px;
  }

  .community-font-2 {
    font-size: 24px;
    line-height: 50px;
  }

  .community-fontSize-2 {
    font-size: 28px;
    line-height: 54px;
}

  .community-font-3 {
    font-size: 36px;
  }

  .community-font-4 {
    font-size: 20px;
  }

  .community-fontSize-5 {
    font-size: 16px;
  }

  .community-fontSize-4 {
    font-size: 18px;
  }

  .question-image-2 {
    left: 10%;
  }

  .community-gradient-image {
    max-height: 550px;
  }
  .community-black-fade {
    top: -160px;
    bottom: -80px;
  }
}

@media screen and (max-width: 992px) {
  .float-user-icons img {
    width: 40px;
  }

  .float-icon-7 {
    left: 25%;
  }

  .float-icon-8 {
    right: 20%;
  }

  .float-icon-9 {
    left: 20%;
    top: 18%;
  }

  .float-icon-10 {
    right: 20%;
    top: 20%;
  }

  .float-noti-icons {
    display: none;
  }

  .community-font-1 {
    font-size: 42px;
  }

  .community-fontSize-1 {
    font-size: 42px;
  }

  .community-font-2 {
    font-size: 21px;
    line-height: 45px;
  }

  .community-fontSize-2 {
    font-size: 24px;
    line-height: 44px;
}

  .community-font-3 {
    font-size: 32px;
    line-height: 50px;
  }

  .question-image-2 {
    left: 0%;
  }

  .community-gradient-image {
    max-height: 480px;
  }

  .community-footer-bg {
    padding-top: 4rem;
    padding-bottom: 10rem;
  }
}

@media screen and (max-width: 780px) {
  .float-user-icons img {
    width: 35px;
  }
  .community-font-1 {
    font-size: 40px;
  }

  .community-fontSize-1 {
    font-size: 40px;
  }

  .community-font-2 {
    font-size: 20px;
    line-height: 45px;
  }

  .community-fontSize-2 {
    font-size: 22px;
    line-height: 36px;
}

  .community-font-3 {
    font-size: 30px;
    line-height: 40px;
  }

  .question-image-2 {
    left: 0%;
  }

  .community-gradient-image {
    max-height: fit-content;
  }

  .question-image-container {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 150px 250px;
  }

  .question-image {
    transform: translateY(50px) translateX(10px) rotateZ(-25deg);
  }

  .community-footer-bg {
    padding-top: 3rem;
    padding-bottom: 8rem;
  }
  .community-black-fade {
    top: -100px;
    bottom: -60px;
  }

  .float-icon-11 {
    left: 20%;
    top: 11%;
  }

  .float-icon-12 {
    right: 19%;
    top: 10%;
  }

  .float-icon-13 {
    left: 13%;
    top: 32%;
  }

  .float-icon-14 {
    right: 12%;
    top: 33%;
  }
}

@media screen and (max-width: 650px) {
  .float-icon-7 {
    left: 18%;
    top: 1%;
  }

  .float-icon-8 {
    right: 15%;
    top: 2%;
  }

  .float-icon-9 {
    left: 15%;
  }

  .float-icon-10 {
    right: 12%;
  }

  .community-font-1 {
    font-size: 30px;
  }

  .community-fontSize-1 {
    font-size: 30px;
  }

  .community-font-2 {
    font-size: 18px;
    line-height: 45px;
  }

  .community-fontSize-2 {
    font-size: 20px;
    line-height: 32px;
}

  .community-font-3 {
    font-size: 24px;
    line-height: 30px;
  }

  .community-font-4 {
    font-size: 16px;
  }

  .community-fontSize-4 {
    font-size: 15px;
  }

  .community-fontSize-5 {
    font-size: 14px;
  }

  .community-footer-bg {
    padding-top: 2.5rem;
    padding-bottom: 5rem;
    background-position-x: center;
    background-position-y: 30% !important;
  }

  .question-image-container {
    grid-template-rows: 120px 220px;
  }
}

@media screen and (max-width: 510px) {
  .float-user-icons img {
    width: 25px;
  }
  .community-font-1 {
    font-size: 22px;
  }

  .community-fontSize-1 {
    font-size: 22px;
  }

  .community-font-2 {
    font-size: 13px;
    line-height: 25px;
  }

  .community-fontSize-2 {
    font-size: 16px;
    line-height: 24px;
}

  .community-font-3 {
    font-size: 17px;
  }

  .community-font-4 {
    font-size: 12px;
  }

  .community-fontSize-4 {
    font-size: 12px;
  }

  .community-fontSize-5 {
    font-size: 109px;
  }

  .question-image {
    transform: translateY(50px) translateX(-20px) rotateZ(-25deg);
  }

  .community-footer-bg {
    padding-top: 2rem;
    padding-bottom: 3rem;
    background-position-x: center;
    background-position-y: 30% !important;
  }

  .question-image-container {
    grid-template-rows: 90px 180px;
  }
  .community-black-fade {
    top: -60px;
    bottom: -30px;
  }

  .float-icon-13 {
    left: 10%;
    top: 40%;
  }

  .float-icon-14 {
    right: 8%;
    top: 38%;
  }
}
