.color-primary{
    color: var(--primary);
}

.color-white{
    color: white;
}

.bg-primary{
    background-color: var(--primary);
}