.talk-sessions-container{
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1.5rem;
}

.talk-container-img{
    width: 100%;
    height: 150px !important;
    min-height: 150px;
    object-fit: cover !important;
    object-position: center;
}

.talk-tab-container{
    width: 200px;
}

@media screen and (max-width: 1350px) {
    .talk-sessions-container{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (max-width: 650px) {
    .talk-sessions-container{
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media screen and (max-width: 500px) {
    .talk-container-img{
        /* height: 250px; */
    }
    .talk-tab-container{
        width: 150px;
    }
}