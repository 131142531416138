.width60 {
    width: 60px;
}

.width160 {
    width: 160px;
}

.gap3 {
    gap: 1rem;
}

.questionDropdown {
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s ease-in-out;
}

.questionDropdown.questionOpen {
    max-height: 500px;
}

.chevronIcon{
    width: 40px !important;
    font-size: 1.75rem !important;
}

.questionContainer{
    height: 400px !important;
}

.scrollHide::-webkit-scrollbar{
    width: 0;
}

.scrollHide::-webkit-scrollbar {
    display: none;
}

.scrollHide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@media screen and (max-width:800px) {
    .questionSearchBox {
        width: 70vw;
        padding: 5px 10px !important;
    }

    .gap3 {
        gap: 0.75rem;
    }

    .width160 {
        width: 100px;
    }

    .width60 {
        width: 40px;
    }
}

@media screen and (max-width:550px) {
    .gap3 {
        gap: 0.2rem;
    }

    .width160 {
        width: 60px;
    }

    .width60 {
        width: 30px;
    }
}