#sidebar-outer-cnt {
    /* font-size: 12px; */
    padding: 50px 24px;
    /* border: 1px solid black; */
}

#mobile-sidebar-outer-cnt {
    /* font-size: 12px; */
    padding: 50px 24px;
    /* border: 1px solid black; */
}

.category-cnt {
    margin-top: 50px;
}

.questions-cnt {
    margin: 22px 0px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 16px;
}

.question-btn {
    border: 1px solid var(--gray-300);
    height: 45px;
    width: 45px;
    font-size: 16px;
    position: relative;
}

.question-btn:hover {
    border: 1px solid var(--gray-600);
}
#options-outer-cnt{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;
    column-gap: 1rem;
}
.next-que-btn {
    border: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 18px;
    height: 37px;
    background: #5865F2;
    border-radius: 4px;
    color: white;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    margin: 20px 0;
    color: #FFFFFF;
}
.next-que-btn:hover {
    background: #5865F2;
    color: #FFFFFF;
}
#integer-que-submit-btn {
    border: none;
    padding: 8px 18px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
}
#previous-btn {
    border: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 18px;
    height: 37px;
    background: #5865F2;
    border-radius: 4px;
    color: var(--primary);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    margin: 20px 0;
}
#contest-ended-outer-cnt{
    height: 100vh;
    width: 100vw;
    z-index: 1021;
    background-color: var(--gray-100);
    position: fixed;
    top: 0px;
    left: 0px;
    /* opacity: 0.7; */
    display: flex;
    align-items: center;
    justify-content: center;
}
#contest-ended-inner-cnt{
    /* z-index: 1001; */
    opacity: 1;
    background-color: white;
    border-radius: 4px;
}
.answered-badge{
    height: 7px;
    width: 7px;
    border-radius: 10rem;
    position: absolute;
    background-color: #08BD80;
    /* top: 0; */
    /* left: 2; */
}
.question-sidebar-btn-cnt{
    display: none !important;
    border-top: 1px solid var(--gray-300);
}

#test-bottom-tab{
    position: fixed;
    bottom: 0;
    width: 100%;
    /* justify-content: space-between !important; */
}

.login-logout-btn {
    border: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 18px;
    height: 37px;
    background: #5865F2;
    border-radius: 4px;
    color: white;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    /* margin: 20px 0; */
    color: #FFFFFF;
}
@media screen and (max-width: 576px) {
    .question-sidebar-btn-cnt{
        display: flex !important;
    }
    #test-bottom-tab{
        position: fixed;
        bottom: 0;
        width: 100%;
        justify-content: space-between !important;
    }
    #sidebar-outer-cnt {
        display: none;
    }
    #options-outer-cnt{
        grid-template-columns: 1fr;
    }
}

@media screen and (min-width: 577px) and (max-width:768px) {
    .question-sidebar-btn-cnt{
        display: flex !important;
    }
    #options-outer-cnt{
        grid-template-columns: 1fr;
    }
    #sidebar-outer-cnt {
        display: none;
    }
}

@media screen and (min-width: 769px) and (max-width:992px) {
    .questions-cnt {
        margin: 22px 0px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 16px;
    }
}

@media screen and (min-width: 993px) and (max-width:1200px) {}

@media screen and (min-width: 1201px) {}