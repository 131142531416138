.shadow-card{
    transition: all 0.3s ease;
}

.shadow-card:hover {
    box-shadow: 1px 3px 6px rgb(46, 46, 46) !important;
}


.btn-1{
    background-color: var(--primary);
    color: white;
    font-weight: 500;
    border-radius: 3px;
    cursor: pointer;
}

.expandCard,
.shrinkCard {
    right: -2rem;
    top: 3.5rem;
    transform: rotate(-90deg);
}

.expandCard {
    right: -2.3rem;
}

.scorecardCont {
    transition: all 0.5s ease-in-out;
}


@media screen and (max-width:990px) {

    .expandCard,
    .shrinkCard {
        transform: rotate(0deg);
        bottom: 0;
        left: 1rem;
        top: auto;
        right: auto;
    }
}

.session-card-font-100{
    font-size: 1.4rem;
}

.session-card-font-80{
    font-size: 0.9rem;
}

.session-card-container{
    width: 8rem;
}

@media screen and (max-width: 1350px) {
    .session-card-font-100{
        font-size: 1rem;
    }
    .session-card-font-80{
        font-size: 0.7rem;
    }
    .session-card-container{
        width: 6rem;
    }
}

@media screen and (max-width: 1100px) {
    .session-card-font-100{
        font-size: 0.8rem;
    }
    .session-card-font-80{
        font-size: 0.5rem;
    }
    .session-card-container{
        width: 5rem;
    }
}

@media screen and (max-width: 990px) {
    .session-card-font-100{
        font-size: 1.4rem;
    }
    .session-card-font-80{
        font-size: 1rem;
    }
    .session-card-container{
        width: 8rem;
    }
}

@media screen and (max-width: 600px) {
    .session-card-font-100{
        font-size: 1rem;
    }
    .session-card-font-80{
        font-size: 0.75rem;
    }
    .session-card-container{
        width: 6rem;
    }
}