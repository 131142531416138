.contestImg {
    height: 15rem;
    object-fit: contain;
}

.contest-type-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* margin: 38px 0px; */
    margin-top: 38px;
    margin-bottom: 38px;
    color: #222222;
}

.contests-outer-cnt {
    margin: 38px 0px;
    padding: 18px 36px;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 6px;
}

.contest-item-img {
    width: 56px;
    height: 56px;
    object-fit: contain;
    margin-right: 24px;
}

.contest-item-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #222222;
    margin-bottom: 8px;
}

.contest-item-name>a:-webkit-any-link {
    color: black;
    cursor: pointer;
    text-decoration: none;
}

.register-btn:hover {
    color: "white" !important;
}

.contest-item-details {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;

    /* identical to box height */
    color: #6B6B6B;
}

.contest-item-details-divider {
    margin: 0px 12px;
}

.contest-result-btn {
    position: absolute;
    bottom: 6px;
    right: 8px;
}

@media screen and (max-width: 576px) {
    .contest-item-img {
        display: none;
    }

    .contests-outer-cnt {
        margin: 0px 0px;
        padding: 0px 0px;
        /* background: #FFFFFF; */
        border: 0px solid #E6E6E6;
        border-radius: 6px;
    }

    .contest-item-details-divider {
        margin: 0px 6px;
    }

    .contest-type-title {
        margin-top: 38px;
        margin-bottom: 12px;
    }
}