#logo {
    width: 134px;
    /* height: 39px; */
    object-fit: contain;
}

#logo-test {
    width: 134px;
    /* height: 39px; */
    object-fit: contain;
}

.navbar {
    padding: 10px 0px;
    /* padding-top: 20px; */
    border-bottom: 1px solid #E6E6E6;
}

.flag-icon {
    height: 1.5rem;
    object-fit: contain;
    margin: 0px 16px;
    /* margin-right: 16px; */
}

#dropdown-icon {
    margin-left: 13px;
}

.dropdown-navbar {
    max-height: max-content !important;
    overflow: visible !important;
}

#category-btn {
    padding-left: 16px;
    border-width: 0px 0px 0px 1px !important;
    border: 1px solid #BABABA;
    height: 32px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 21.3333px;
    line-height: 32px;
    color: #000000;
}

.navbar-icon {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-bottom: 6px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    color: #000000;
}

.navbar-btn-cnt {
    margin-right: 40px;
    cursor: pointer;
    position: relative;
    padding-bottom: 16px;
}

.bottom-btn-cnt {
    /* margin-right: 40px; */
    cursor: pointer;
    position: relative;
    padding-bottom: 4px;
}

.navbar-btn-cnt>.carousel>.carousel-inner>.carousel-item {
    transition: transform .3s linear;
}

.navbar-btn-cnt>.carousel>.carousel-inner {
    width: 21px;
}

.navbar-btn-cnt::after {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 4px;
    background-color: #5865F2;
    content: "";
    transition: all 0.3s ease-in;
}

.navbar-btn-hover::after {
    width: 100%;
}

.navbar-btn-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    transition: all 0.3s ease-in;
}

.login-logout-btn {
    border: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 18px;
    height: 37px;
    background: #5865F2;
    border-radius: 4px;
    color: white;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    /* margin: 20px 0; */
    color: #FFFFFF;
}

#user-icon {
    object-fit: contain;
    /* height: 20px; */
    /* width: 20px; */
}

.navbar-profile-icon {
    height: 42px;
    width: 42px;
    border-radius: 10rem;
    border: none;
    align-self: center;
}

#navbar-btn-outer-cnt {
    position: relative;
    top: 10px;
}

#sign-in-button {
    /* display: none !important; */
}

@media screen and (max-width: 576px) {
    #navbar-btn-outer-cnt {
        display: none !important;
    }

    #logo-test {
        display: none !important;
    }

    #test-navbar-btn {
        width: 100%;
        justify-content: space-between;
    }
}