.numberSectionContainer{
    border-radius: 1rem;
}

.width80 {
    width: 80px;
}

.numberCardSubContainer{
    width: 18rem !important;
    height: 18rem !important;
}

.numberCard {
    display: flex;
}

.numberCardSubContainer{
    transition: all 0.5s ease;
    transform: translateY(-100px) rotate(0deg);
    opacity: 0.2;
}

.numberCardFont1 {
    font-size: 3rem;
}

.numberCardFont2 {
    font-size: 1.3rem;
}

.animate1 {
    transform: translateY(17px) rotate(-4deg) !important;
    opacity: 1;
}

.animate2 {
    transform: translateY(15px) !important;
    opacity: 1;
}

.animate3 {
    transform: translateY(17px) rotate(4deg) !important;
    opacity: 1;
}

@media screen and (max-width:1100px) {
    .numberSectionContainer{
        width: 100% !important;
        border-radius: 0;
    }
    .numberCard {
        flex-wrap: wrap;
        justify-content: center;
        gap: 48px;
    }

    .width80 {
        width: 80px;
    }

    .numberCard div {
        transform: rotate(0deg) !important;
        top: 0 !important;
    }

    .numberCardFont1 {
        font-size: 2.7rem;
    }

    .numberCardFont2 {
        font-size: 1.4rem;
    }
}