#loader-1 {
    position: fixed;
    top: 0;
    z-index: 5000;
    /* border: 2px solid black; */
    width: 100%;
    background: linear-gradient(-45deg, #1082d3, #141ba2, var(--primary), #23d5ab);
    /* background: linear-gradient(0deg, var(--primary), white); */
    background-size: 400% 400%;
    animation: gradient 2s ease infinite;
    height:  7px;

}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
