.summary-item-cnt {
    /* width: 301px; */
    /* height: 128px; */
    background: #FFFFFF;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.03), 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    /* padding-top: 26px; */
    /* padding-bottom: 24px; */
    /* padding-left: 26px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* margin-right: 67px; */
    /* margin-top: 39px; */
}

.result-summary-outer-cnt {
    max-width: 1450px;
    border-bottom: "#E4E4E4";
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
}

.summary-item-title {
    font-family: 'Poppins';
    font-style: normal;
    line-height: 27px;
    text-transform: uppercase;
    color: #5E5E5E;
}

.subject-analysis-outer-cnt {
    /* height: 247px; */
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    margin-top: 46px;
    padding-top: 32px;
    padding-bottom: 53px;
    padding-right: 89px;
    position: relative;
    padding-left: 39px;
    width: fit-content;
    transition: all 0.3s ease-in !important;
}

.subject-result-bar {
    height: 18px;
    min-width: 300px;
    margin-top: 20.5px;
}

.my-score-outer-cnt {
    transition: all 0.3s ease-in;
}

@media screen and (max-width: 576px) {
    .result-summary-outer-cnt {
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        row-gap: 1rem;
    }

    .summary-item-cnt {
        padding-top: 0.5rem !important;
        padding-left: 0.7rem !important;
        padding-bottom: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

    .summary-item-title {
        font-size: 0.7rem !important;
        margin-bottom: 1rem;
    }

    .summary-item-value {
        font-size: 1rem !important;
    }

    .summary-item-lower-cnt {
        align-self: end;
    }

    .compare-btn {
        position: fixed ;
        bottom: 0.5rem;
        right: 0.5rem;
    }
}

@media screen and (min-width: 577px) and (max-width:768px) {}

@media screen and (min-width: 769px) and (max-width:992px) {}

@media screen and (min-width: 993px) and (max-width:1200px) {}

@media screen and (min-width: 1201px) {
    .subject-result-bar {
        width: 512px;
    }

}