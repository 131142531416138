.table-fixed-cols1{
    width:75%;
    box-sizing: border-box;
}

.table-fixed-cols2{
    width:60%;
    box-sizing: border-box;
}

.table-fixed-cols3{
    width:50%;
    box-sizing: border-box;
}

.table-scroll-cols1{
    width: 25%;
}

.table-scroll-cols2{
    width: 40%;
}

.table-scroll-cols3{
    width: 50%;
}

.fixed-cols-grid{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    justify-items: start;
}

.scroll-cols-grid1{
    display: grid;
    grid-template-columns: 1fr;
}

.scroll-cols-grid2{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.scroll-cols-grid3{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width:900px) {
    .table-fixed-cols3{
        width:500px;
    }
    .table-scroll-cols3{
        width:300px;
    }
}

@media screen and (max-width:800px) {
    .table-fixed-cols2{
        width:500px;
    }
    .table-scroll-cols2{
        width:200px;
    }
}

@media screen and (max-width:700px) {
    .table-fixed-cols1{
        width:500px;
    }
    .table-scroll-cols1{
        width: 100px;
    }
}