/* community css  */

.community-page-wrapper {
    background-color: black;
    color: white;
    width: 100%;
    height: 100%;
}

.community-page-wrapperV2 {
    background-color: '#030014';
    color: white;
    width: 100%;
    height: 100%;
}