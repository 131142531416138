  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;1,100&display=swap');

  :root {
    --primary: #5865F2;
    --primary-dark: #4655BE;
    --secondary: #707070;
    --gray-100: #f8f9fa;
    --gray-200: #e9ecef;
    --gray-300: #dee2e6;
    --gray-400: #ced4da;
    --gray-500: #adb5bd;
    --gray-600: #6c757d;
    --gray-700: #495057;
    --gray-800: #343a40;
    --gray-900: #212529;
    --black: #121212;
    --red-100: #E36963;
    --red-200: #de3f37;
    --green-100: #79C285;
    --green-200: #3D944B;
    --purple-100: #F4F5FF;
    --purple-200: #DADDFF;
    --yellow-50: #feffe3;
    --yellow-100: #FCE488;
    --yellow-200: #FFC107;
    --white: #ffffff;
  }


/* class for cursor  */
  .cursor{
    cursor: pointer;
  }

  a {
    text-decoration: none !important;
    color: unset;
  }

  body {
    margin: 0;
    padding: 0;
    transition: all 0.5s ease-in;
    font-family: 'Poppins';
  }

  * {
    font-family: 'Poppins';
  }

  .fw-l {
    font-weight: 300 !important;
  }

  .fw-r {
    font-weight: 400 !important;
  }

  .fw-m {
    font-weight: 500 !important;
  }

  .fw-sb {
    font-weight: 600 !important;
  }

  .fw-b {
    font-weight: 700 !important;
  }

  .fs-sm {
    font-size: 0.9rem !important;
  }

  .fs-xsm {
    font-size: 0.8rem !important;
  }

  .fs-xxsm {
    font-size: 0.7rem !important;
  }

  .fs-xxsm {
    font-size: 0.7rem !important;
  }

  .clr-p {
    color: #05517C;
  }

  .clr-white {
    color: white;
  }

  .bg-1 {
    /* background-color: #1c4980; */
    background-color: var(--primary) !important;
  }

  .bg-1-light {
    background-color: #eff3f7 !important;
  }

  .bg-2 {
    background-color: #553d67 !important;
    /* background-color: #242582; */
  }

  .bg-3 {
    background-color: #f64c72 !important;
    /* background-color: #242582; */
  }

  .bg-4 {
    background-color: #99738e !important;
    /* background-color: #99738e; */
  }

  .bg-5 {
    background-color: #2f2fa2;
  }

  .bg-gray-1 {
    background-color: var(--gray-200) !important;
  }

  .bg-gray-2 {
    background-color: var(--gray-400) !important;
  }

  .bg-gray-3 {
    background-color: var(--gray-600) !important;
  }

  .text-1 {
    color: var(--primary) !important;
  }

  .text-2 {
    color: var(--secondary);
  }

  .text-3 {
    color: #f64c72;
  }

  .text-4 {
    color: #99738e;
  }

  .text-5 {
    color: #2f2fa2;
  }

  .text-gray-1 {
    color: var(--gray-500) !important;
  }

  .text-gray-2 {
    color: var(--gray-600) !important;
  }

  .text-gray-3 {
    color: var(--gray-700) !important;
  }

  .text-gray-4 {
    color: var(--gray-800) !important;
  }

  .text-gray-5 {
    color: var(--gray-900) !important;
  }

  .no-focus:focus {
    /* border-color: black; */
    outline: none;
  }

  .border-color-3 {
    border: 1px solid #08BD80 !important;
  }

  .border-color-1 {
    border: 1px solid var(--primary) !important;
  }

  .border-light {
    border: 1px solid #dee2e6;
  }

  .border-white {
    border: 1px solid #dee2e6;
  }

  .icon-1 {
    height: 24px;
    width: 24px;
    object-fit: contain;
  }

  .icon-cnt {
    box-shadow: 0px 16px 32px 0px var(--gray-200);
    background-color: var(--gray-200);
    border-radius: 8px;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    padding: 0px;
    display: grid;
    place-items: center;
    margin-right: 12px;
  }

  .icon-cnt-lg {
    width: 148px;
    height: 148px;
    object-fit: cover;
    border-radius: 101px;
    border: 2px solid white;
  }


  /* hover effects*/
  .border-hover-1:hover {
    border-color: var(--primary) !important;
  }

  .border-hover-3:hover {
    border-color: #f64c72 !important;
  }

  .text-hover-1:hover {
    color: #1c4980 !important;
  }

  .text-hover-3:hover {
    color: #f64c72 !important;
  }

  .text-hover-light:hover {
    color: white !important;
  }

  .bg-hover-1:hover {
    background-color: var(--primary) !important;
  }

  .bg-hover-1-light:hover {
    background-color: #eff3f7 !important;
  }

  .bg-hover-3:hover {
    background-color: #f64c72 !important;
  }

  .border-focus:focus {
    border-color: #5D001E !important;
  }

  .dropdown-menu {
    /* width: 100% !important; */
    /* min-width: 0rem; */
    --bs-dropdown-link-active-bg: white;
    /* margin-top: 0rem !important; */
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .delay-1 {
    animation-delay: 0.2s !important;
  }

  .delay-2 {
    animation-delay: 0.4s !important;
  }

  .hover-pointer:hover {
    cursor: pointer;
  }

  .container-1 {
    max-width: 960px;
    margin: auto auto;
    width: 100%;
  }

  @media screen and (max-width: 576px) {
    .d-mobile-none {
      display: none !important;
    }

    .d-mobile-flex {
      display: flex !important;
    }

    .offcanvas.offcanvas-end {
      width: 100% !important;
    }

    .px-res-1 {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .bg-light {
      background-color: white !important;
    }
  }

  @media screen and (min-width: 577px) and (max-width:768px) {
    .offcanvas.offcanvas-end {
      width: 75% !important;
    }

    .px-res-1 {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }


  }

  @media screen and (min-width: 769px) and (max-width:992px) {
    .offcanvas.offcanvas-end {
      width: 50% !important;
    }

    .px-res-1 {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

  }

  @media screen and (min-width: 993px) and (max-width:1200px) {

    .offcanvas.offcanvas-end {
      width: 40% !important;
    }

    .px-res-1 {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  @media screen and (min-width: 1201px) {
    .px-res-1 {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .offcanvas.offcanvas-end {
      width: 40% !important;
    }

    .container {
      max-width: 960px;
    }
  }



  /*boostrap related styles */
  .btn.show {
    border-color: transparent;
  }

  .btn.show:active {
    border-color: transparent;
  }

  .btn:active {
    border-color: transparent !important;
  }

  .btn:disabled {
    background-color: var(--gray-200) !important;
    color: var(--gray-600) !important;
    border-color: var(--gray-200) !important;
  }

  .btn-close {
    --bs-btn-close-focus-shadow: none;
  }