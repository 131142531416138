.font-120{
    font-size: 1.20rem;
}

.font-150{
    font-size: 1.30rem;
}

.font-100{
    font-size: 1rem;
}
.font-usercard-90{
    font-size: 0.9rem;
}
.font-80{
    font-size: 0.8rem;
}

.font-200{
    font-size: 2rem;
}

.font-usercard-100{
    font-size: 0.9rem;
}
.font-usercard-80{
    font-size: 0.7rem;
}
.font-usercard-60{
    font-size: 0.6rem;
}

.contestCategoryTitle {
    font-size: 1.25rem;
}

.contestCategoryTitle::-webkit-scrollbar {
    display: none;
}

.contestCategoryTitle {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.contest-card-btn{
    width: 9rem;
}

@media screen and (max-width:1400px) {
    .font-usercard-120{
        font-size: 0.9rem;
    }
    .font-usercard-100{
        font-size: 0.8rem;
    }
    .font-usercard-80{
        font-size: 0.6rem;
    }
    .font-usercard-60{
        font-size: 0.55rem;
    }
    .contest-card-btn{
        width: 8rem;
    }
}

@media screen and (max-width:1200px) {
    .font-usercard-120{
        font-size: 0.7rem;
    }
    .font-usercard-100{
        font-size: 0.6rem;
    }
    .font-usercard-90{
        font-size: 0.75rem;
    }
    .font-usercard-80{
        font-size: 0.45rem;
    }
    .font-usercard-60{
        font-size: 0.52rem;
    }
    .contest-card-btn{
        width: 7.5rem;
    }
}

@media screen and (max-width:990px) {
    .font-usercard-120{
        font-size: 1.2rem;
    }
    .font-usercard-100{
        font-size: 1rem;
    }
    .font-usercard-90{
        font-size: 0.6rem;
    }
    .font-usercard-80{
        font-size: 0.8rem;
    }
    .font-usercard-60{
        font-size: 0.5rem;
    }
    .contest-card-btn{
        width: 7.2rem;
    }
}

@media screen and (max-width:600px) {
    .contestCategoryTitle {
        font-size: 1.05rem;
    }
    .font-120{
        font-size: 0.9rem;
    }
    .font-150{
        font-size: 1.2rem;
    }
    .font-100{
        font-size: 0.8rem;
    }
    .font-usercard-90{
        font-size: 0.9rem;
    }
    .font-80{
        font-size: 0.6rem;
    }
    .font-200{
        font-size: 1.5rem;
    }
    .contest-card-btn{
        width: 6rem;
    }
}

@media screen and (max-width:500px) {
    .contestCategoryTitle {
        font-size: 0.9rem
    }
    .font-120{
        font-size: 0.8rem;
    }
    .font-150{
        font-size: 0.9rem;
    }
    .font-100, .font-usercard-100{
        font-size: 0.75rem;
    }
    .font-usercard-90{
        font-size: 0.6rem;
    }
    .font-80, .font-usercard-80{
        font-size: 0.55rem;
    }
    .font-200{
        font-size: 1.2rem;
    }
}

@media screen and (max-width:400px) {
    .contestCategoryTitle {
        font-size: 0.8rem
    }
    .font-120{
        font-size: 0.75rem;
    }
    .font-150{
        font-size: 0.8rem;
    }
    .font-100, .font-usercard-100{
        font-size: 0.7rem;
    }
    .font-80, .font-usercard-80{
        font-size: 0.5rem;
    }
    .font-200{
        font-size: 1.1rem;
    }
}