.width600 {
    width: 600px;
}

.bottom10{
    bottom: -10rem;
}

@media screen and (max-width:1200px) {
    .width600 {
        width: 500px;
    }
    .bottom10{
        bottom: -6rem;
    }
}

@media screen and (max-width:900px) {
    .width600 {
        width: 400px;
    }
    .bottom10{
        bottom: -4rem;
    }
}

@media screen and (max-width:600px) {
    .width600 {
        width: 300px;
    }
    .bottom10{
        bottom: 0rem;
    }
}