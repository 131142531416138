.app_container{
    background-color: var(--black) !important;
    color: white !important;
}

.app_contest_card{
    background-color: 'transparent';
    border: 1px solid var(--gray-600);
}

.app_contest_ranking_table{
    background-color: 'transparent';
    border: 1px solid var(--gray-600);
}