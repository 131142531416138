.btn-property {
    width: fit-content;
    padding: 12px 25px;
    outline: 0;
    border: 0;
    border-radius: 8px;
    font-weight: 600;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.button-type1 {
    background-color: var(--primary);
    color: white;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.1);
}

.button-type1:hover {
    background-color: var(--primary-dark);
    box-shadow: 0;
    color: white;
}

.button-type2 {
    background-color: rgba(255, 255, 255, 1);
    color: var(--primary);
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.1);
}

.button-type2:hover {
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0;
    color: var(--primary);
}

@media screen and (max-width:992px) {
    .btn-property {
        padding: 10px 20px;
        font-size: 18px;
        border-radius: 6px;
    }
}

@media screen and (max-width:600px) {
    .btn-property {
        padding: 8px 15px;
        font-size: 15px;
        border-radius: 5px;
    }
}

@media screen and (max-width:500px) {
    .btn-property {
        padding: 6px 12px;
        font-size: 10px;
        border-radius: 4px;
    }
}