

#instruction-btn {
    color: var(--primary) !important;
    border: 1px solid var(--primary);
}


@media screen and (max-width: 576px) {
    #test-info-btns {
        position: fixed;
        bottom: 0;
        margin-bottom: 1rem;
    }

    #test-info-cnt {
        border-bottom: 1px solid var(--gray-200) !important;
        padding-bottom: 0rem !important;
        border-radius: 0 !important;
    }

}