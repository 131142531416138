.wrapBoxCol{
    flex-direction: row;
    justify-content: space-between;
}

.width400{
    width: 400px;
}

.linkFoot{
    color: inherit;
    text-decoration: none;
}

.linkFoot:hover{
    /* color: inherit; */
}

@media screen and (max-width:800px) {
    .width400{
        width: 300px;
    }
    .wrapBoxCol{
        flex-direction: column;
        justify-content: center;
        gap: 3rem !important;
        /* align-items: center; */
    }
    .wrapBoxCol div{
        width: 100% !important;
    }
}