.PracticeQuestionTabSection{
    overflow-x: auto;
    overflow-y: visible;
}

.PracticeQuestionMain{
    display: grid;
    grid-template-columns: minmax(0, 1fr) 3fr;
    gap: 1rem;
}

.QuestionViewOptionContainer{
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
}

.QuestionViewTabOption{
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr 1fr 1fr;
    overflow-x: auto;
}

@media screen and (max-width: 1250px) {
    .PracticeQuestionMain{
        display: grid;
        grid-template-columns: minmax(0, 2fr) 5fr;
        gap: 1rem;
    }
}

@media screen and (max-width: 1000px) {
    .PracticeQuestionMain{
        display: grid;
        grid-template-columns: minmax(0, 3fr) 5fr;
        gap: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .PracticeQuestionMain{
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        gap: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .QuestionViewTabOption{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 500px) {
    .QuestionViewOptionContainer{
        grid-template-columns: 1fr;
    }
}