.overview_cards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
}

@media screen and (max-width:800px) {
    .overview_cards{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width:450px) {
    .overview_cards{
        gap: 0.3rem;
    }
}