.referral-title{
    color: var(--gray-700);
}

.referral-card{
    background-color: var(--white);
}

.referral-btn{
    color: var(--white);
    background-color: var(--primary);
    height: fit-content;
}