.about-line {
    left: 20%;
    top: 30%;
}

.about-card-shadow {
    box-shadow: 0 .3rem 0.8rem rgba(0, 0, 0, .15);
    transition: all 0.4s;
    cursor: pointer;
}

.about-card-shadow:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(2px);
}

.team-profiles {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    width: 100%;
    gap: 1rem;
    justify-items: center;
    align-items: center;
}

.team-profiles>div {
    padding-top: 5px;
    width: 100%;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
    cursor: pointer;
}

.team-profiles>div .show-names {
    padding: 5px 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    color: white;
    text-align: center;
}

.team-profiles img {
    transition: all 0.2s ease;
}

.team-profiles>div:hover img {
    transform: scale3d(1.07, 1.07, 1.07);
}

.team-profiles>div:nth-child(odd) {
    background-color: #AFB6FF;
}

.team-profiles>div:nth-child(even) {
    background-color: #FEF8E6;
}

.team-profiles img {
    width: 100%;
}

.curveline {
    top: 25%;
}

@media screen and (max-width:1330px) {
    .about-line {
        top: 20%;
    }
}

@media screen and (max-width:992px) {
    .about-line {
        top: 30%;
    }
}

@media screen and (max-width:880px) {
    .about-line {
        top: 15%;
    }
}

@media screen and (max-width:1000px) {
    .curveline {
        top: 15%;
    }
}

@media screen and (max-width:576px) {
    .team-profiles {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}