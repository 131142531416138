.table-entry {
    display: grid;
    grid-template-columns: auto 1fr auto;
    border-bottom: 1px solid var(--gray-200);
}

.active>.page-link {
    /* width: 40px; */
    background-color: var(--primary);
    
}
.page-item>.page-link{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10rem !important;
    width: 40px;
    height: 40px;
    text-align: center;
    margin: 0px 4px;
}
.page-item.disabled > .page-link{
    background-color: var(--gray-200);
    color: var(--secondary);
}