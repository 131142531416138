.ProfileContainer {
    display: grid;
    grid-template-columns: minmax(0, 4fr) minmax(0, 15fr);
    grid-template-rows: 1fr;
    column-gap: 1rem;
}

.profile-overview-container {
    grid-area: 1/2/2/3;
}

.profile-img{
    width: 60px;
}

.profile-ranks {
    display: grid;
    grid-template-columns: repeat(4, minmax(120px, 1fr));
    gap: 1rem;
}

.profile-btn {
    color: var(--primary);
    border: 2px solid var(--primary);
}

.profile-btn:hover {
    color: var(--white);
    background-color: var(--primary);
}

.profile-logout-btn {
    color: var(--red-200);
    border: 2px solid var(--red-200);
}

.profile-logout-btn:hover {
    color: var(--white);
    background-color: var(--red-200);
}

@media screen and (max-width: 1300px) {
    .ProfileContainer {
        grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
    }
}

@media screen and (max-width: 1100px) {
    .ProfileContainer {
        grid-template-columns: minmax(0, 4fr) minmax(0, 10fr);
    }
}

@media screen and (max-width: 992px) {
    .profile-ranks {
        grid-template-columns: repeat(2, minmax(120px, 1fr));
    }
    .ProfileContainer {
        grid-template-columns: 1fr;
    }
    .profile-overview-container {
        grid-area: 1/1/2/2;
    }
    .profile-img{
        width: 10%;
        min-width: 35px;
    }
}


.profile-overview-container::-webkit-scrollbar{
    width: 0;
}