/* .contestListsTable tbody tr{
    back
} */

.practice-icons-grid {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 15px;
}

.practice-side-image{
    width: 100%;
    max-width: 200px;
}

.contestListsTable thead tr th {
    font-weight: 500;
}

.practice-briefInfo-wrapper {
    background: linear-gradient(89.67deg, #F8F8F8 -34%, rgba(242, 243, 255, 0.25) 25.04%, rgba(246, 254, 255, 0.86) 100%);
    background: linear-gradient(60deg, #F8F8F8 0%, rgba(242, 243, 255, 0.25) 44.06%, rgba(246, 254, 255, 0.86) 100%)
}

.subject-topic-card-grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
}

@media screen and (max-width:1200px) {
    .subject-topic-card-grid {
        grid-template-columns: repeat(3, 1fr);
    }
    .practice-icons-grid {
        flex-direction: column;
        align-items: start;
        gap: 8px;
    }
}

@media screen and (max-width:760px) {
    .subject-topic-card-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .practice-icons-grid {
        flex-direction: row;
        align-items: center;
        gap: 15px;
    }
    .practice-side-image{
        max-width: 150px;
    }
}

@media screen and (max-width:650px) {
    .practice-icons-grid {
       flex-direction: column;
       gap: 5px;
    }
}

@media screen and (max-width:576px) {
    .subject-topic-card-grid {
        grid-template-columns: repeat(1, 1fr);
    }
    .practice-side-image{
        max-width: 120px;
    }
}