.option-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.solution-dropDown{
    max-height: 0;
    transition: all 0.2s ease-in;
    opacity: 0;
}

.solution-dropDown.solution-open{
    max-height: 600px;
    overflow-y: auto;
    opacity: 1;
}


@media screen and (max-width:500px) {
    .option-grid{
        grid-template-columns: 1fr;
    }
}